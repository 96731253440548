<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md12 lg11 xl8>
        <v-data-table
          dense
          :headers="headers"
          :items="empresasLista"
          class="elevation-10"
          :search="search"
          :loading="!empresasProgress"
          loading-text="Carregando..."
          :footer-props="{
            'items-per-page-options': [10, 20, 30],
          }"
          sortBy="id"
          sort-desc
        >
          <template v-slot:progress>
            <v-progress-linear
              color="primary"
              :height="4"
              indeterminate
            ></v-progress-linear>
          </template>

          <template v-slot:top>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-domain</v-icon>
              <v-toolbar-title>Empresas</v-toolbar-title>
              <v-spacer></v-spacer>
              <!-- <v-dialog v-model="dialog" max-width="500px"> -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metRecarregar()"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="selecionarEmpresaEdicao()"
                  >
                    mdi-plus-circle-outline
                  </v-icon>
                </template>
                <span>Novo Empresa</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
              <!-- </v-dialog> -->
            </v-toolbar>
            <v-container>
              <v-row dense>
                <!-- <v-col cols="6" class="ml-2" align-self="end">
                  <v-chip-group
                    mandatory
                    active-class="primary"
                    show-arrows
                  >
                    <v-chip @click="listaCliAtivos = 'ativos'"> Ativos </v-chip>
                    <v-chip @click="listaCliAtivos = 'inativos'">
                      Inativos
                    </v-chip>
                    <v-chip @click="listaCliAtivos = 'todos'"> Todos </v-chip>
                  </v-chip-group>
                </v-col> -->
                <v-col cols="auto" md="4" class="ml-1 mb-0" align-self="end">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Busca"
                    single-line
                    hide-details
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:[`item.cria_datahora`]="{ item }">
            <span>{{ mixDataDBD(item.cria_datahora) }} </span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  id="tooltip"
                  class="mr-2"
                  color="blue darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="selecionarEmpresaEdicao(item)"
                  :disabled="permissao('Pensil')"
                >
                  mdi-lead-pencil
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import register from "./../_store/register";
// import ConfirmDialogue from "@/components/ConfirmDialogue.vue";
import DatasMixin from "@/mixins/DatasMixin";
// import moment from "moment";

const { mapState, mapActions } = createNamespacedHelpers("lojaEmpresas");

export default {
  // components: { ConfirmDialogue },
  mixins: [DatasMixin],
  name: "LojaEmpresasLista",
  data: () => ({
    mostraPrazo: false,
    listaCliAtivos: "ativos",
    search: "",
    dialog: false,
    headers: [
      { text: "Código", value: "id", align: "start" },
      {
        text: "Alias",
        align: "start",
        sortable: true,
        value: "alias",
      },
      {
        text: "Razão Social",
        align: "start",
        sortable: true,
        value: "razaosocial",
      },
      { text: "CNPJ", value: "cnpj", align: "start" },
      { text: "Ações", value: "actions", sortable: false },
    ],
    perm: [
      {
        field: "Pensil",
        subject: "action",
        component: "LojaClientesComprasLista",
        group: [5],
      },
    ],
  }),

  computed: {
    ...mapState(["erro", "empresas", "empresaSelecionado"]),

    empresasLista() {
      return this.$store.state.lojaEmpresas.empresas;
    },
    empresasProgress() {
      // console.log(!this.$store.state.lojaEmpresas.empresas.length);
      return this.$store.state.lojaEmpresas.empresas;
    },
  },

  created() {
    register(this.$store);
    this.listarEmpresas(this.$store.state.login.licenca);
  },

  watch: {
    erro() {
      if (this.erro) {
        this.$root.snackbar.show({
          type: "danger",
          message: this.erro,
        });
        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 3500);
      }
    },
  },

  mounted() {
    //this.obterEmpresas();
  },

  methods: {
    ...mapActions([
      "deletarEmpresa",
      "listarEmpresas",
      "selecionarEmpresa",
      "resetarEmpresa",
      "ativarEmpresa",
    ]),

    permissao(field) {
      if (this.perm.some((t) => t.field == field)) {
        const permObject = this.perm.filter((t) => t.field == field);
        if (permObject[0].group[0] == this.$store.state.login.usuario.grupo) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    // formataData(data) {
    //   return moment(data).utc().format("DD-MM-YYYY");
    // },
    // formataDataIn(data) {
    //   return moment(data).utc().format("YYYY-MM-DD");
    // },

    ativoColor(ativo) {
      if (ativo) return "green darken-1";
      else return "red darken-1";
    },

    initialize() {
      /*       if (this.empresas === null) {
        this.empresas = null;
      } */
    },
    login() {
      this.$router.push({ name: "Login" });
    },
    novoEmpresa() {
      if (this.empresaSelecionado) {
        this.resetarEmpresaSelecionado();
        return;
      }
    },

    selecionarEmpresaEdicao(empresa) {
      this.selecionarEmpresa({ empresa });
      this.$router.push({ name: "LojaEmpresasForm" });
    },

    paraAtivacao(empresa) {
      // if (empresa.data_cadastro != "0000-00-00") {
      empresa.data_cadastro = this.formataDataIn(empresa.data_cadastro);
      // }
      // if (empresa.ultima_compra != "0000-00-00") {
      empresa.ultima_compra = this.formataDataIn(empresa.ultima_compra);
      // }
      this.ativarEmpresa({ empresa });
      // this.listarEmpresas();
    },
    resetarEmpresa() {
      this.resetarEmpresaSelecionado();
    },
    metVoltar() {
      this.$router.back();
    },
    metRecarregar() {
      this.listarEmpresas(this.$store.state.login.licenca);
      this.$root.snackbar.show({
        type: "info",
        message: "Lista atualizada!",
      });
    },
  },
};
</script>

<style></style>
